<template>
  <el-form ref="form" :model="form" label-width="150px" class="elForm">
    <el-form-item label="会员年费：">
      <el-input placeholder="请输入内容" v-model="form.price" style="width: 300px">
        <template slot="append">元</template>
      </el-input>
    </el-form-item>
    <el-form-item label="会员送优惠券福利：">
      <el-switch v-model="form.is_coupons" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      <br />
      <ul>
        <li v-for="(item, index) in form.coupons_data" :key="index">
          <div>
            <label for="" style="margin-right: 20px">赠送优惠券</label>
            <el-select v-model="item.coupons_id" placeholder="">
              <el-option v-for="items in couponsList" :disabled="items.id == item.coupons_id ? true : false" :key="items.id" :label="items.coupon_name" :value="items.id"></el-option>
            </el-select>
          </div>
          <div>
            <label for="" style="margin-right: 20px; margin-top: 8px; display: inline-block">数量</label>
            <el-input placeholder="请输入数量" v-model="item.num" style="width: 300px">
              <template slot="append">张</template>
            </el-input>
          </div>
          <span v-if="index >= 1" @click="delService(item.id)" style="margin-top: 17px; color: #409eff; display: inline-block">删除</span>
        </li>
      </ul>
      <el-link style="color: blue; margin-top: 20px" @click="addService">+添加优惠券</el-link>
    </el-form-item>
    <el-form-item label="vip免工时费">
      <el-input v-model="form.vip_no_hour_cost" placeholder="数值" style="width: 300px; margin: 0 10px"></el-input>
      小时
    </el-form-item>
    <el-form-item label="会员等级设置：">
      <el-switch v-model="form.is_level" active-color="#13ce66" inactive-color="#ff4949" style="margin-right: 20px"></el-switch>
      <span>注意：下面每添加一个等级，积分都要比上一条数据的积分高，要求会员积分不要出现重叠或者间隔</span>
      <br />
      <ul>
        <li v-for="(item, index) in form.level" :key="index">
          <div>
            <label for="" style="margin-right: 20px">会员等级名称</label>
            <el-input placeholder="请输入会员等级名称" v-model="item.name" style="width: 300px"></el-input>
          </div>
          <div>
            <label for="" style="margin-right: 20px; margin-top: 8px; display: inline-block">要求会员积分&nbsp;&nbsp;&nbsp;>=</label>
            <el-input placeholder="积分" v-model="item.integral" style="width: 300px">
              <template slot="append">积分</template>
            </el-input>
          </div>
          <span v-if="index >= 1" @click="delVIP(item.id)" style="margin-top: 17px; color: #409eff; display: inline-block">删除</span>
        </li>
      </ul>
      <el-link style="color: blue; margin-top: 20px" @click="addVIP">+添加会员等级</el-link>
    </el-form-item>
    <el-form-item label="会员权益：">
      <RichText :richTxt="form.content" @soninfo="val => (form.content = val)"></RichText>
    </el-form-item>
    <el-form-item class="centerBtn">
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import RichText from '@/components/richText';
export default {
  components: {
    RichText,
  },
  data() {
    return {
      form: {
        price: '',
        is_coupons: 0,
        coupons_data: [],
        vip_no_hour_cost: 0,
        is_level: 0,
        content: '',
        level: [],
      },
      info: {},
      couponsList: [],
      // 优惠券
      serviceAreaList: [
        {
          id: 1,
          name: 'hh',
        },
      ],
      // 会员等级
      VIPList: [
        {
          id: 1,
          name: 'hh',
        },
      ],
    };
  },
  created() {
    this.getCoupons();
    this.getInfo();
  },
  methods: {
    onSubmit() {
      this.form.is_coupons = this.form.is_coupons ? 1 : 0;
      this.form.is_level = this.form.is_level ? 1 : 0;
      let data = this.form;
      let url = '';
      if (this.info) {
        url = this.$api.repair.marketing.cardSetEdit;
      } else {
        url = this.$api.repair.marketing.cardSetAdd;
      }
      this.$axios.post(url, data).then(res => {
        if (res.code === 0) {
          this.getInfo();
        }
      });
    },
    getInfo() {
      this.$axios.post(this.$api.repair.marketing.cardSetInfo, {}).then(res => {
        if (res.code === 0) {
          this.info = res.result;
          this.form = this.info;
          this.form.coupons_data = this.info.coupons_data_r ? this.info.coupons_data_r : [];
          this.form.level = this.info.level ? this.info.level : [];
          this.form.is_coupons = this.info.is_coupons ? !0 : !1;
          this.form.is_level = this.info.is_level ? true : false;
          console.log(this.info);
        }
      });
    },
    getCoupons() {
      let data = {
        page: 1,
        rows: 10000,
        status: 1,
      };
      this.$axios.post(this.$api.repair.marketing.couponList, data).then(res => {
        if (res.code === 0) {
          this.couponsList = res.result.list;
        }
      });
    },
    // 添加优惠券
    addService() {
      this.form.coupons_data.push({
        coupons_id: '',
        num: 0,
      });
      console.log(this.form.coupons_data);
    },

    // 删除优惠券
    delService(id) {
      let index = this.form.coupons_data.findIndex(item => item.id == id);
      console.log(index);
      this.form.coupons_data.splice(index, 1);
    },

    // 添加会员等级
    addVIP() {
      this.form.level.push({
        name: '',
        integral: 0,
      });
      console.log(this.form.level);
    },

    // 删除会员等级
    delVIP(id) {
      let index = this.form.level.findIndex(item => item.id == id);

      this.form.level.splice(index, 1);
      console.log(this.form.level);
    },
  },
};
</script>

<style lang="less" scoped>
.elForm {
  background-color: #fff;
  padding: 30px;
}

.centerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul li {
  display: flex;

  div {
    margin: 10px 10px 0 0;
  }
}
</style>
